.dialog-box {
    min-width: 500px;
}

@media only screen and (max-width: 700px) {
    .dialog-box {
        min-width: unset;
    }    
}

/* 
.dialog-box {
    width: 600px;
} */

canvas {
    width: 100%;
    height: 150px;
}

.dialog-box .header-text {
    color: white;
    height: 40px;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    background-color: #010A43;
}

.dialog-box .close-button {
    
}

.dialog-box .dialog-main {
    padding: 20px;
}

.dialog-box .dialog-footer {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
    border-top: solid 1.5px;
    gap: 10px;
}

.dialog-box .dialog-footer > button {
  font-size: 12px;
  text-transform: none;
  padding: 0;
  height: 34px;
}


