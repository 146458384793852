.container {
  position: relative;
}

.content {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  align-items: center;
  gap: 0.5rem;
}

.content:before,
.content:after {
  content: '';
  height: 1px;
  background-color: #CBD5E1;
}
